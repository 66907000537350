#Header {
    width: 100%;
    height: 100vh;
    background: url('../../../assets/background.jpg') no-repeat center center;
    background-size: cover;
}


.header-text {
    width: 47%;
    margin: auto;
    padding-top: 375px;
}

.header-title {
    font-weight: 700;
    letter-spacing: -0.042em;
    line-height: 0.94em;
    color: #f2c71a;
    font-size: 100px;
}

.header-content {
    font-size: 50px;
    font-weight: 200;
    line-height: 1.333em;
    margin:  0;
    color: #fff;
    font-family: "Libre Baskerville";
    font-weight: bold;
}

.logo {
  margin: 20px;
  position: fixed;
} 

.header-logo {
  min-width: 250px;
  width: 45%;
}

/*---------------------------*/
#Approach {
  background: white;
	background-size: 400% 400%;
  margin: 0;
}

.partner-title {
    font-size: 34px;
    font-family: "Libre Baskerville";
    font-style: normal;
    font-weight: 700;
    text-align: start;
    color: black;
}

.approach_include {
    padding: 10% 0;
}

.item-col {
  width: 250px;
}

.partnering {
    width: 55%;
    margin: auto;
}

.partner-subtitle {
    font-size: 12px;
    color: #E9EEEE;
    text-transform: uppercase;
    padding-top: 70px;
    position: relative;
    letter-spacing: 1px;
    font-weight: 500;
}

.partner-details {
    color: rgb(0, 0, 0);
    font-size: 16px;
    line-height: 30px;
    margin-top: 30px;
    border-bottom: 2px solid #192329;
    padding-bottom: 20px;
}

.intro-criteria-container {
    margin-top: 100px;
    grid-column-start: auto;
    grid-row-start: auto;
    border-radius: 25px;
    background-color: rgba(100, 100, 100, 0.13);
    color: rgb(70, 71, 93);
    box-shadow: rgb(0 0 0 / 25%) 0px 2px 8px;
    padding: 0 4% 30px 4%;
}

.criteria-heading {
    background: #00116e;
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-family: Roboto, sans-serif;
    top: 0px;
    padding: 8px 10px;
    border-radius: 0px 0px 12px 12px;
    text-transform: uppercase;
    width: fit-content;
    margin: auto;
}

.criteria-link {
    background-color: #7AC344;
    color: #ffffff;
    padding: 15px 20px;
    font-size: 16px;
    letter-spacing: 1px;
    display: table;
    text-align: center;
    margin: auto;
    font-weight: 600;
    position: relative;
    left: 0;
    right: 0;
    max-width: 220px;
    width: 220px;
    margin-top: 60px;
    border: 1px solid #7AC344;
}

.intro-criteria-container a {
    text-decoration: none;
}

.image-title {
    color: #000;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    width: 100%;
}

.image-item {
  border-radius: 24px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 8px;
  margin: 1%;
}

.parent_container {
  margin-top: 1%;
    display: flex;
    text-align: center;
    justify-content: center;
}

.image-container{
    margin-top: 40px;
    height: 100px;
    width: 100%;
    display: flex;
}

.img-grp {
    display: block;
    margin: auto;
    max-width: 60%;
    max-height: 70px;
    height: 100%;
    vertical-align: middle;
}

aside {
    color: #fff;
    width: 250px;
    padding-left: 20px;
    height: 100vh;
    background-image: linear-gradient(30deg , #0048bd, #44a7fd);
    border-top-right-radius: 80px;
  }
  
  aside a {
    font-size: 12px;
    color: #fff;
    display: block;
    padding: 12px;
    padding-left: 30px;
    text-decoration: none;
    -webkit-tap-highlight-color:transparent;
  }
  
  aside a:hover {
    color: #3f5efb;
    background: #fff;
    outline: none;
    position: relative;
    background-color: #fff;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  
  aside a i {
    margin-right: 5px;
  }
  
  aside a:hover::after {
    content: "";
    position: absolute;
    background-color: transparent;
    bottom: 100%;
    right: 0;
    height: 35px;
    width: 35px;
    border-bottom-right-radius: 18px;
    box-shadow: 0 20px 0 0 #fff;
  }
  
  aside a:hover::before {
    content: "";
    position: absolute;
    background-color: transparent;
    top: 38px;
    right: 0;
    height: 35px;
    width: 35px;
    border-top-right-radius: 18px;
    box-shadow: 0 -20px 0 0 #fff;
  }
  
  aside p {
    margin: 0;
    padding: 40px 0;
  }


/*-------------*/

.sidebar-grp {
    position: fixed;
    right: 2%;
}  

.sidebar-each {
    opacity: 0.8;
    background: white;
    color: black;
    text-align: center;
    padding: 30%;
    width: fit-content;
    box-shadow: 5px 4px 4px black;
    border-radius: 50%;
}

.sidebar-box a {
    text-decoration: none;
}

#sidebar-header {
    position: fixed;
    margin: 0px 46px 32px 0px;
    right: 148px;
}

#sidebar-portfolio {
    right: 117px;
    top: 151px;
    position: fixed;
    margin: 0px 46px 32px 0px;
}

#sidebar-footer {
    right: 27px;
    top: 197px;
    position: fixed;
    margin: 0px 46px 32px 0px;
}




/* font ---*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap');
.effect-text {
  font-family: 'Poppins', sans-serif;
  font-size:100px;
  text-transform: uppercase;
  overflow: hidden;
}
.disappear {
  position: absolute;
  left:0;
  right:0;
  margin: auto;
  width:80%;
  text-align: center;
  height:fit-content;
  bottom:0;
  top:0;
}
.disappear span {
  display: inline-block;
  color:transparent;
  text-shadow: 0px 0px 0px #7AC344;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
.disappear span:nth-child(1){
  animation-name: disappearleft;
}
.disappear span:nth-child(2){
  animation-name: disappearleft;
  animation-delay: .3s;
}
.disappear span:nth-child(3){
  animation-name: disappearight;
  animation-delay: .6s;
}
.disappear span:nth-child(4){
  animation-name: disappearleft;
  animation-delay: .8s;
}
.disappear span:nth-child(5){
  animation-name: disappearight;
  animation-delay: 1s;
}
.disappear span:nth-child(6){
  animation-name: disappearight;
  animation-delay: 1.3s;
}
.disappear span:nth-child(7){
  animation-name: disappearleft;
  animation-delay: 1.6s;
}
.disappear span:nth-child(8){
  animation-name: disappearight;
  animation-delay: 2s;
}
.disappear span:nth-child(9){
  animation-name: disappearight;
  animation-delay: 2.3s;
}
.disappear span:nth-child(10){
    animation-name: disappearight;
    animation-delay: 2.7s;
  }
@keyframes disappearleft{
  50% {transform: skew(50deg) translateY(-200%);
      text-shadow: 0px 0px 50px;
        opacity: 0;
  }
}
@keyframes disappearight{
  50% {transform: skew(-50deg) translateY(-200%);
      text-shadow: 0px 0px 50px;
        opacity: 0;
  }
}

@media screen and (max-width: 820px) {
  .header-logo {
    width: 100%;
    max-width: 250px;
  }

  .header-text {
    padding-top: 45%;
    width: 80%;
  }

  .header-title {
    margin: 5% 0;
    font-weight: 700;
    letter-spacing: -0.042em;
    line-height: 0.94em;
    color: #f2c71a;
    font-size: 55px;
  }

  .header-content {
    font-size: 50px;
    font-weight: 200;
    line-height: 1.333em;
    margin: 0 10px 0 0;
    color: #fff;
    text-align: left;
  }

  .partnering {
    width: 100%;
    margin: auto;
  }

  .partner-title {
    font-family: "big-caslon",serif;
    font-size: 40px;
    text-align: center;
  }
}
