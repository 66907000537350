
// n is number of stars required
@function multiple-box-shadow ($n) {

  $value: '#{random(2000)}px #{random(2000)}px #FFF';
  @for $i from 2 through $n{
    $value: '#{$value} , #{random(2000)}px #{random(2000)}px #FFF';
  }
  
  @return unquote($value);
}
  
  $shadows-small:  multiple-box-shadow(700);
  $shadows-medium: multiple-box-shadow(200);
  $shadows-big:    multiple-box-shadow(100);

#Header{
  height: 100vh;
  background: url('../../../../assets/background.webp') no-repeat center center;
  background-size: cover;
  overflow: hidden;
}
  
#stars {
  width: 1px;
  height: 1px;
  border-radius: 50%;
  opacity: 0.7;
  background: transparent;
  box-shadow: $shadows-small;
  animation			: animStar 50s linear infinite;
  &:after {
      
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    opacity: 0.7;
    border-radius: 50%;
    background: transparent;
    box-shadow: $shadows-small;
    }
}
  
    
  
    
#stars2{
  width: 5px;
  height: 5px;
  opacity: 0.7;
  border-radius: 50%;
  background: transparent;
  box-shadow: $shadows-medium;
  animation			: animStar 100s linear infinite;
    
  &:after{
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: transparent;
    box-shadow: $shadows-medium;
  }
}
#stars3{
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: transparent;
  box-shadow: $shadows-big;
  animation			: animStar 150s linear infinite;
    
  &:after{

    content: " ";
    position: absolute;
    top: 2000px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: transparent;
    box-shadow: $shadows-big;
  }
}

#title{

  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  color: #FFF;
  text-align: center;
  font-family: 'lato',sans-serif;
  font-weight: 300;
  font-size: 50px;
  letter-spacing: 10px;
  
  margin-top: -60px;
  padding-left: 10px;
}
    
@keyframes animStar {
  
  from	{

    transform: translateY(-2000px)
  }
  to{

    transform: translateY(0px)
  }		
}

@media screen and (max-width: 600px) {
  #Header{
    height: 100vh;
    background: url('../../../../assets/back-mobile.webp') no-repeat center center;
    background-size: cover;
    overflow: hidden;
  }
}
    