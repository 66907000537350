.curtain-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    color: red;
    overflow: hidden;
    z-index: 999;
}

.curtain {
    width: 200vw;
    height: 200vw;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    background: #083142;
    -ms-transform: translate(-100%, 0) skewX(-20deg);
    transform: translate(-100%, 0) skewX(-20deg);
    -ms-transform-origin: right top;
    transform-origin: right top;
    animation-name: example;
    animation-duration: 0.7s;
}

@keyframes example {
    0%   {transform-origin: right top;}
    100% { transform: translate(100%, 0%) skewX(-20deg); transform-origin: bottom left;}
  }
