.topics {
    background: #00116e;
    font-weight: bolder;
    text-align: center;
    padding: 6%;
    margin: 4% 0;
    color: #fff;
    font-size: 50px;
}

.privacy {
    width: 70%;
    margin: 70px auto;    
}

.context-title {
    color: black;
    opacity: 0.9;
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 2rem;
}

.context-text {

}